import React, {createRef, useContext, useEffect} from "react";
import PageWrapper from "./../components/PageWrapper";
import Seo from "./../components/Seo";
import {Section, Title, Text, Button, MainTitle} from "./../components/Core";
import { Container, Row, Col } from "react-bootstrap";
import { navigate } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../utils/helperFn";
import animation from "./../assets/animations/about-us-main.json"
import styled from "styled-components";
import lottie from "lottie-web"
import aboutUs1 from "./../assets/image/rebrand/about-us-1.jpg"
import aboutUs2 from "./../assets/image/rebrand/about-us-2.jpg"
import deepGradient from "./../assets/image/rebrand/deep-gradient.svg"
import netomniaTeam from "./../assets/image/rebrand/netomnia-team.png"
import aboutUsHero from "./../assets/image/rebrand/about-us-hero.jpg"

export const RadiusImage = styled.img`
    width: 100%;
    border-radius: 20px;
`

export const GradientSection = styled(Section)`
    background: url(${deepGradient}) no-repeat;
    background-size: cover;
    text-align: center;
    padding-bottom: 0;
`

export const HeroSection = styled(Section)`
    text-align: center;
    position: relative;
    background-color: #fff;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 100px;

    h1 {
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
    }
    img {
        opacity: 0.5;
    }
`

const AboutUs = () => {

    const data = useStaticQuery(graphql`
        query strapiAboutUsPageQuery {
            strapiAboutUsPage {
                title
                subtitle
                text_1
                text_2
                text_3
                text_4
                management_button_text
                careers_button_text
            }
            strapiPageHeadings(slug: {eq: "about-us"}) {
                h1
            }
        }`
    );

    const pageData = getProperty(data, 'strapiAboutUsPage');
    const headingsData = getProperty(data, 'strapiPageHeadings');

    const animationContainer = createRef()

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animation
        });
        return () => anim.destroy(); // optional clean up for unmounting
    }, []);

    return (
        <>
            <Seo page="about-us"/>
            <PageWrapper footerDark>
                <HeroSection>
                    <img src={aboutUsHero} className="w-100" />
                    <MainTitle>{headingsData.h1}</MainTitle>
                </HeroSection>
                <Section>
                    <Container>
                        <Row>
                            <Col lg="6" className="centered">
                                <Text>
                                    We’re Netomnia, the fastest-growing full
                                    fibre network operator specialising in
                                    connecting homes and businesses to
                                    ultrafast FTTP broadband.
                                </Text>
                                <Text>
                                    We centre what we do around the idea that
                                    everyone should have access to opportunity,
                                    wherever they are, whatever their story. To
                                    this end, we empower hundreds of
                                    thousands of people across the UK to
                                    discover their passions, hone skills, pursue
                                    interests with ease, through a seamless
                                    connection to all the resources the Internet
                                    has to offer.
                                </Text>
                            </Col>
                            <Col lg={6}>
                                <RadiusImage src={aboutUs1} />
                            </Col>
                        </Row>
                        <Row className="mt-5 pt-5">
                            <Col lg={6}>
                                <RadiusImage src={aboutUs2} />
                            </Col>
                            <Col lg="6" className="centered">
                                <Text>
                                    With decades of industry experience between
                                    us, we’re here to accelerate the UK’s transition
                                    to full fibre. We founded our company in 2019
                                    to level the digital playing field and bring you
                                    the benefits of ultrafast full fibre connectivity.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <GradientSection>
                    <Container>
                        <Row className="text-center mt-5">
                            <Col sm="12">
                                <Title variant="news" className="white-color">Get to know us</Title>
                                <Button
                                    onClick={() => navigate('/team')}
                                >{pageData.management_button_text}</Button>
                                <img src={netomniaTeam} className="w-100 mt-5" />
                            </Col>
                        </Row>
                    </Container>
                </GradientSection>
            </PageWrapper>
        </>
    )

}

export default AboutUs;
